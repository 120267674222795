body, html {
      font-family: 'Roboto', sans-serif;
      height: 100%;
      margin: 0;
    }
    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh; /* This makes sure your container is always 100% of the viewport height */
      background-color: rgba(0,0,0,0.9); /* Dark purple background */
    }
    h1 {
      font-family: 'Jost', sans-serif;
      color: #87F335;
      font-size: 94px;
    }